<template>
  <q-drawer
    v-model="ui.quasar.sides.left"
    :breakpoint="ui.quasar.breakpoints.left"
    :mini="ui.quasar.mini.mode"
    :mini-width="ui.quasar.mini.width"
    elevated
    show-if-above
    side="left"
    animated
  >
    <q-scroll-area class="fit">
      <!-- Mobility Nearby buttons -->
      <div class="q-px-lg q-pt-lg mobility">
        <strong>{{ $t('mobility_nearby') }}</strong>
        <div class="row q-my-md justify-center">
          <div
            v-for="(item, key) in mobilityAndBookablesCombined"
            :key="`mobilityNearbyButton${key}`"
            class="col-3 q-mb-md text-center nearby-item"
            :class="isTypeEnabled(item.type, travelPreferences[typeToContentTypeMapper(item.type)]) ? null : 'is-disabled'"
            @click="isTypeEnabled(item.type, travelPreferences[typeToContentTypeMapper(item.type)]) ? isPolicyEnabled(item.type) ? searchNearby(item.type, item.pref) : showWarning(item.type, 'nearby') : null"
          >
            <q-tooltip v-if="!isTypeEnabled(item.type, travelPreferences[typeToContentTypeMapper(item.type)])" :target="`#mobilityNearbyButton${key}`">
              {{ $t('no_permission_to_search') }}
            </q-tooltip>
            <template v-if="item.icon.resource === 'library'">
              <q-btn
                :id="`mobilityNearbyButton${key}`"
                unelevated round
                :color="item.color.icon"
                :icon="`fas fa-${item.icon.name}`"
                :class="item.icon.name === 'taxi' ? 'q-mb-sm nearby-icon-contrast' : 'q-mb-sm nearby-icon'"
                :text-color="item.color.text || 'white'"
              />
              <div class="text-subtitle2">
                {{ item.label }}
              </div>
            </template>
            <template v-else-if="item.icon.resource.includes('assets', 's3')">
              <q-btn
                :id="`mobilityNearbyButton${key}`"
                unelevated round
                class="q-mb-sm nearby-icon"
              >
                <q-avatar v-if="item.icon.resource === 'assets'" size="42px" rounded>
                  <img :src="require(`assets/${item.icon.name}`)" alt="">
                </q-avatar>
                <!-- TODO: S3 variant of <q-avatar> where the require() doesn't die -->
              </q-btn>
              <div class="text-subtitle2">
                {{ item.label }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <q-dialog
        v-model="isWarningVisible"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <q-card class="text-center" square>
          <q-card-section class="full-height">
            <p v-html="$t('transport_policy_disabled_detail')" />
            <p v-html="$t('transport_policy_disabled_continue')" />
          </q-card-section>
          <q-card-actions>
            <q-btn v-close-popup color="primary" unelevated :label="$t('back')" />
            <q-btn v-if="actionType === 'book'" v-close-popup color="primary" outline :label="$t('continue')" @click.stop="$router.push({ name: `ondemand-${contentType}` })" />
            <q-btn v-else-if="actionType === 'nearby'" v-close-popup color="primary" outline :label="$t('continue')" @click.stop="searchNearby(contentType, typeToContentTypeMapper(contentType))" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Smartsearch mini form -->
      <div class="smartsearch bg-primary">
        <div class="q-pa-md">
          <div class="row">
            <div class="col column justify-around">
              <q-icon name="fas fa-route" class="fa-2x" />
            </div>
            <div class="col-10">
              <p class="text-weight-medium">
                {{ $t('smartsearch.smartsearch') }}
              </p>
              <p class="text-subtitle1">
                {{ $t('smartsearch.a_to_b') }}
              </p>
              <p class="text-subtitle2">
                {{ $t('smartsearch.plan_your_journey') }}
              </p>
            </div>
          </div>
          <div class="row q-col-gutter-sm q-pt-md">
            <div class="col column">
              <m-select-query
                v-model="smartsearchParams.origin"
                :placeholder="$t('choose_origin')"
                icon="fas fa-location"
                dense
                outlined
                color="primary-tint"
                bg-color="white"
                :query="smartsearchAutocomplete"
                smart-search
                @focus="onSmartSearchFocus('origin')"
              />
              <m-select-query
                v-model="smartsearchParams.destination"
                :placeholder="$t('choose_destination')"
                icon="fas fa-map-marker-alt"
                dense
                outlined
                color="primary-tint"
                bg-color="white"
                :query="smartsearchAutocomplete"
                smart-search
                @focus="onSmartSearchFocus('destination')"
              />
            </div>
            <div class="col-1 column justify-around q-pa-xs">
              <q-btn icon="fas fa-sort-alt" flat dense @click="smartsearchSwapLocations" />
            </div>
          </div>
        </div>
        <div class="smartsearchFooter bg-grey-3 q-py-sm q-px-md" :class="{ 'expanded': smartsearchAdvancedMode }">
          <div v-if="smartsearchAdvancedMode" class="row">
            <div class="col-12">
              <date-field
                v-model="smartsearchParams.depart"
                :min="minDate"
                :label="this.$t('date.travel')"
              />
            </div>
            <div class="col-12">
              <time-field
                v-model="smartsearchParams.depart"
                :min="minDate"
                :label="$t('time.from')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-btn
                v-if="!smartsearchAdvancedMode"
                :label="smartsearchLeavingLabel"
                dense
                color="primary"
                flat
                no-caps
                icon="far fa-clock"
                @click="smartsearchAdvancedMode = true"
              />
            </div>
            <div class="col-4">
              <q-btn
                :label="$t('smartsearch.leave_now')"
                dense
                :disabled="!validSmartsearch"
                color="primary"
                class="full-width"
                no-caps
                @click="$store.dispatch('smartsearch/initialQuery'); smartsearchAdvancedMode = false"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Book Now buttons -->
      <div class="q-px-lg q-pt-lg bookNow">
        <strong>{{ $t('book_now') }}</strong>
        <p class="text-subtitle2">
          {{ $t('know_what_you_need') }} <strong>{{ $t('book_below') }}:</strong>
        </p>
        <div class="row q-col-gutter-md q-py-md">
          <div v-for="bookable in authPartner.bookables.filter(b => isTypeEnabled(b.type, travelPreferences[typeToContentTypeMapper(b.type)]))" :key="`bookable-${bookable.type}`" :class="`col-${bookable.colspan || 6}`">
            <q-btn
              :label="bookable.label"
              :icon="`fas fa-${bookable.icon.name}`"
              class="full-width bookTypeButton"
              :class="`org-enabled-${isPolicyEnabled(bookable.type)}`"
              :align="bookable.align || 'left'"
              color="grey-2"
              no-caps
              unelevated
              @click="isPolicyEnabled(bookable.type) ? $router.push({ name: `ondemand-${bookable.type}` }) : showWarning(bookable.type, 'book')"
            >
              <q-badge v-if="!isPolicyEnabled(bookable.type)" rounded floating color="orange" />
            </q-btn>
          </div>
        </div>
      </div>
      <div class="mobileApp q-px-lg q-mb-sm">
        <p class="q-pt-sm">
          {{ $t('download_app', { partner: authPartner.title }) }}:
        </p>
        <div class="row">
          <div class="col">
            <q-img class="cursor-pointer" :src="require('assets/download-play-store.png')" @click="externalUrl(authPartner.appLinks.android)" />
          </div>
          <div class="col">
            <q-img class="cursor-pointer" :src="require('assets/download-app-store.png')" @click="externalUrl(authPartner.appLinks.ios)" />
          </div>
        </div>
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { query as placeQuery } from 'api/places'
import date from 'utils/date-time'
import loading from 'utils/loading'
const { addToDate, roundMinutesUp, luxonFormatDate } = date
import { isTypeEnabled, typeToContentTypeMapper, isPolicyEnabled } from 'utils/contentTypes'
import { timeField, dateField } from 'components/'

export default {
  name: 'LayoutBasicSidebar',
  components: { timeField, dateField },
  mixins: [authentication],
  data () {
    return {
      smartsearchAdvancedMode: false,
      tooltip: {
        offset: [-64, 0],
        anchor: 'top left',
        self: 'top left',
        delay: 300
      },
      smartsearchLeavingLabel: this.$t('smartsearch.choose_a_time'),
      isTypeEnabled,
      typeToContentTypeMapper,
      policyDisabledCount: 0,
      isPolicyEnabled,
      isWarningVisible: false,
      contentType: null,
      actionType: null
    }
  },
  computed: {
    ...mapGetters({
      ui: 'ui',
      latLng: 'geolocate/latLng',
      smartsearchParams: 'smartsearch/parameters',
      smartsearchLoading: 'smartsearch/loading',
      user: 'userLookup',
      authPartner: 'partner',
      organisation: 'organisation',
      travelPreferences: 'preferences'
    }),
    validSmartsearch () {
      return this.smartsearchParams.origin && this.smartsearchParams.destination
    },
    minDate () {
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 30 }), 15)
    },
    mobilityAndBookablesCombined () {
      // Return a compound list of mobility and bookables, sans any bookables that are intentionally excluded; e.g. hotels
      let arr = [...this.authPartner.mobility, ...this.authPartner.bookables].filter(item => !item.excludeFromMobilityNearby && !item.excludeFromDesktop)
      return arr.sort((a, b) => a?.order - b?.order)
    }
  },
  watch: {
    'smartsearchParams.depart': {
      deep: true,
      handler (val) {
        this.smartsearchLeavingLabel = luxonFormatDate(val, 'dd LLL HH:mm')
      }
    },
    latLng: {
      deep: true,
      handler (val) {
        this.smartsearchParams.origin = {
          icon: 'my_location',
          label: this.$t('current_location'),
          value: `${val.latitude},${val.longitude}`
        }
      }
    },
    smartsearchLoading (value, old) {
      if (value === true) {
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: this.authPartner.slug,
          spinnerColor: this.authPartner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (this.errors) {
          this.$q.dialog({
            title: this.$t('error.search_unsuccessful'),
            message: this.$t('error.try_later'),
            color: 'primary',
            ok: true,
            cancel: this.$t('leave_feedback')
          })
            .onCancel(() => {
              var win = window.open('https://mobilleo.com/resources/support/', '_blank')
              win.focus()
            })
        } else {
          this.$router.push({ name: 'smart-search-results' })
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('smartsearch/setRequester', this.user)
    this.$store.dispatch('smartsearch/setTraveller', this.user)
  },
  methods: {
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    isUserDisabled (type) {
      return this.user.travel_preferences[type] ? this.user.travel_preferences[type].hidden : false
    },
    smartsearchSwapLocations () {
      [this.smartsearchParams.destination, this.smartsearchParams.origin] = [
        this.smartsearchParams.origin,
        this.smartsearchParams.destination
      ]
    },
    smartsearchAutocomplete (terms) {
      return placeQuery(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    },
    searchNearby (type, pref) {
      if (['drt'].includes(type)) {
        this.$router.push({ name: `ondemand-${type}` })
        return
      }
      if (['trains', 'car-hire', 'flights'].includes(type)) {
        type = pref
      }
      if (this.$route.name !== 'dashboard') this.$router.push({ name: 'dashboard' })
      this.$store.dispatch('discovery/searchNearby', { searchType: type, fresh: true })
    },
    onSmartSearchFocus (name) {
      if (this.smartsearchParams[name]?.label?.toLowerCase() === 'current location') this.smartsearchParams[name] = null
    },
    showWarning (type, action) {
      if (!this.isWarningVisible) {
        this.isWarningVisible = true
        this.contentType = type
        this.actionType = action
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'home-search') {
      this.$root.$children[0].$refs.layout.$refs.sheet.scrollToBottom()
      setTimeout(next, 100)
    } else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>
.smartsearch
  .text-subtitle1
    font-size .75em
    line-height 1em
  .text-subtitle2
    font-size .78em
    line-height 1.5em

.nearby-icon
  color #fff
.nearby-icon-contrast
  color #222
.nearby-item
  .text-subtitle2
    font-size .75em
.bookTypeButton
  font-size .75em
.bookNow
  .text-subtitle2
    font-weight 400
    font-size .75em
    line-height 1.35em
.mobileApp
  position absolute
  left 0
  right 0
  font-size .75em
  text-align center
.smartsearchFooter
  height 48px
  transition height .5s
  overflow hidden
  &.expanded
    height 176px
.text-small
  font-size 0.8rem
</style>
